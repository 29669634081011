




































































































import { computed, defineComponent, ref } from '@vue/composition-api'
import { AUTH_REQUEST, AUTH_CONFIRM } from '@/api/auth'

export default defineComponent ({
  data() {
    return {
      id1: null,
      id2: null,
      id3: null,
      pin: null
    };
  },
  created () {
    if (this.isAuth) {
      this.$router.push('Home')
    }
  },
  setup (props, ctx) {
    const email = ref('')
    const password = ref('')
    const code = ref(null)
    const store = ctx.root.$store
    const passwordType = ref('password')

    const visibility = (type) => {
      if (type === 'show') {
        passwordType.value = 'text'
      }

      if (type === 'hide') {
        passwordType.value = 'password'
      }
    }

    const Authentication = () => {
      AUTH_REQUEST({ email: email.value, password: password.value })
    }

    const clear = () => {
      store.dispatch('AUTH_CANCEL')
    }

    const isAuth = computed(() => {
      return store.getters.isAuthenticated
    })
    return {
      Authentication,
      email,
      password,
      store,
      code,
      isAuth,
      visibility,
      passwordType,
      clear
    }
  },
  computed: {
    id() {
      return this.id1+'-'+this.id2+'-'+this.id3;
    }
  },
  methods: {
    checkAct() {
      document.location = `/up?id=${this.id}&pin=${this.pin}`;
    }
  }
})


