import axios from 'axios'
import store from '@/store'
import { setCookie, deleteCookie } from '@/helpers/cookie'

export function AUTH_REQUEST (data) {
  return new Promise<any>((resolve, reject) => {
    axios.post('/api/login', data).then((response) => {
      if (response.data.success === true) {
        store.dispatch('SET_AUTH_STATUS', 'request-success')
        return new Promise<any>((resolve, reject) => {
          setCookie('api_token', response.data.api_token, { expires: 3600 * 24 * 365 })
          store.dispatch('AUTH_CONFIRM', response.data.api_token)
          console.log(axios.defaults.headers.common.Authorization)
        })
      }
    }).catch(() => {
      store.dispatch('SET_AUTH_STATUS', 'error')
      store.dispatch('AUTH_ERROR', 'Неправильный логин или пароль')
    })
  })
}

export function AUTH_CONFIRM (data) {
  console.log(data)
  axios.post('/api/login', data).then((response) => {
    return new Promise<any>((resolve, reject) => {
      setCookie('api_token', response.data.api_token, { expires: 3600 * 24 * 365 })
      store.dispatch('AUTH_CONFIRM', response.data.api_token)
      console.log(axios.defaults.headers.common.Authorization)
    })
  }).catch(() => {
    store.dispatch('SET_AUTH_STATUS', 'error')
    store.dispatch('AUTH_ERROR', 'Неправильный логин или пароль')
  })
}
